.ant-card-head-title {
    white-space: pre-wrap!important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}


.inter-font {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.backButton {
      font-family: "Inter", sans-serif;
      color: darkgray;
}


.backButton:hover {
    color: gold;
    cursor: pointer;
}